var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-top-list2 table-row-top"},[_c('vue-snotify'),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('font-awesome-icon',{attrs:{"icon":"align-left"}}),_vm._v("  "),_c('span',[_vm._v(_vm._s(_vm.$t("list_user"))+" ")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Add New User'),expression:"'Add New User'",modifiers:{"hover":true,"bottom":true}}],staticClass:"ui btn-sm basic button",staticStyle:{"margin":"5px padding: .25rem .65rem","float":"right"},attrs:{"variant":"success","title":"Add New User"},on:{"click":function($event){return _vm.onActionClicked('create-user', null, null)}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Add New User ")],1)],1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row tbl-margin-top"},[_c('div',{staticClass:"col table-responsive"},[_c('vuetable',{ref:"usertable",staticClass:"table table-striped table-bordered nowrap vue-tbl",attrs:{"api-url":_vm.getUrl,"fields":_vm.fields,"pagination-path":"","per-page":10,"row-class":_vm.onRowClass,"http-options":_vm.httpOptions,"append-params":_vm.moreParams},on:{"vuetable:pagination-data":_vm.onPaginationData},scopedSlots:_vm._u([{key:"actions",fn:function(props){return [_c('div',{staticClass:"custom-actions",staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('b-dropdown',{staticClass:"ui btn-sm basic button",attrs:{"variant":"info","right":"","text":"Actions"}},[(!props.rowData.deleted_at)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onActionClicked(
                        'edit-user',
                        props.rowData,
                        props.rowIndex
                      )}}},[_vm._v(" Edit ")]):_vm._e(),(!props.rowData.deleted_at && !_vm.hasRole('AGENCY MANAGER'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onActionClicked(
                        'clear-session',
                        props.rowData,
                        props.rowIndex
                      )}}},[_vm._v(" Clear Session ")]):_vm._e(),(!props.rowData.deleted_at && _vm.hasPermission('login-as-user') && !_vm.hasRole('AGENCY MANAGER'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onActionClicked(
                        'login-as-user',
                        props.rowData,
                        props.rowIndex
                      )}}},[_vm._v(" Login As ")]):_vm._e(),(!props.rowData.deleted_at && !_vm.hasRole('AGENCY MANAGER'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onActionClicked(
                        'change-password',
                        props.rowData,
                        props.rowIndex
                      )}}},[_vm._v(" Change Password ")]):_vm._e(),(!props.rowData.deleted_at)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onActionClicked(
                        'deactivate-user',
                        props.rowData,
                        props.rowIndex
                      )}}},[_vm._v(" "+_vm._s(props.rowData.active == 1 ? "Deactivate" : "Activate")+" ")]):_vm._e(),(!props.rowData.deleted_at && !_vm.hasRole('AGENCY MANAGER'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onActionClicked(
                        'delete-user',
                        props.rowData,
                        props.rowIndex
                      )}}},[_vm._v(" Delete ")]):_vm._e(),(props.rowData.deleted_at && !_vm.hasRole('AGENCY MANAGER'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onActionClicked(
                        'restore-user',
                        props.rowData,
                        props.rowIndex
                      )}}},[_vm._v(" Restore ")]):_vm._e()],1)],1)]}},{key:"branches-slot",fn:function(props){return _c('div',{},[_c('div',[_vm._v(" "+_vm._s(props.rowData.branch ? props.rowData.branch.branch_name : '')+" "),_vm._l((props.rowData.branches),function(brnc,index){return _c('div',{key:index,attrs:{"index":index}},[_vm._v(" "+_vm._s(brnc.branch_name)+" ")])})],2)])}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"pagination"},[_c('vuetable-pagination',{ref:"pagination",on:{"vuetable-pagination:change-page":_vm.onChangePage}})],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }